import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from '@modules/auth/services';

const routes: Routes = [
    { path: '',
    pathMatch: 'full',
    redirectTo: '/dashboard' },
    {
        path: 'home',
        loadChildren: () =>
            import('modules/home/home-routing.module').then(m => m.HomeRoutingModule),
    },
    {
        path: 'charts',
        loadChildren: () =>
            import('modules/charts/charts-routing.module').then(m => m.ChartsRoutingModule),
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('modules/dashboard/dashboard-routing.module').then(
                m => m.DashboardRoutingModule
            ),
    },
    {
        path: 'balances',
        loadChildren: () =>
            import('modules/balance/balance-routing.module').then(
                m => m.BalanceRoutingModule
            ),
    },
    {
        path: 'debts',
        loadChildren: () =>
            import('modules/debts/debts-routing.module').then(
                m => m.DebtsRoutingModule
            ),
    },
    {
        path: 'lotteries',
        loadChildren: () =>
            import('modules/lottety/lottery-routing.module').then(
                m => m.LotteryRoutingModule
            ),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
    {
        path: 'tables',
        loadChildren: () =>
            import('modules/tables/tables-routing.module').then(m => m.TablesRoutingModule),
    },
    {
        path: 'version',
        loadChildren: () =>
            import('modules/utility/utility-routing.module').then(m => m.UtilityRoutingModule),
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
