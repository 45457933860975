import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('skip') == null) {
      let tokenUser = localStorage.getItem('tokenUser');
      if(tokenUser) {
        var tokenObj = JSON.parse(tokenUser);
        req = req.clone({
          setHeaders: {
            'Content-Type' : 'application/json; charset=utf-8',
            'Accept'       : 'application/json',
            'Authorization': `Bearer ${tokenObj.accessToken}`,
          },
        });
      }
    }
    return next.handle(req);
  }
}